
<div class="form-container">
    <button class="back-button" (click)="goBack()">
        <mat-icon>arrow_back</mat-icon>
    </button>
    <h2>New Unit</h2>
    <form [formGroup]="unitForm">
      <div class="form-row">
        <div class="form-group col-sm-12 col-md-12 col-lg-4">
          <label for="unitName">Unit Name<span class="requried-field">*</span></label>
          <input
            type="text"
            id="unitName"
            placeholder="Enter unitName"
            class="form-control"
            formControlName="unitName"
            [ngClass]="{ 'is-invalid': submitted && f['unitName'].errors }"
          />
          <div *ngIf="submitted && f['unitName'].errors" class="invalid-feedback">
            <div *ngIf="f['unitName'].errors['required']">Unit Name is required</div>
          </div>
        </div>
 
        <div class="form-group col-lg-4 col-md-6 col-sm-12">
            <label for="shortName">Short<span class="requried-field">*</span></label>
            <input
              type="text"
              id="shortName"
              placeholder="Enter short"
              class="form-control"
              formControlName="shortName"
              [ngClass]="{ 'is-invalid': submitted && f['shortName'].errors }"
            />
            <div *ngIf="submitted && f['shortName'].errors" class="invalid-feedback">
              <div *ngIf="f['shortName'].errors['required'] ">Short is required</div>
            </div>
        </div>
        <!-- <div class="form-group col-lg-4 col-md-6 col-sm-12">
            <label for="ustatus">Status<span class="requried-field">*</span></label>
            <input
              type="text"
              id="ustatus"
              placeholder="Enter status"
              class="form-control"
              formControlName="ustatus"
              [ngClass]="{ 'is-invalid': submitted && f['ustatus'].errors }"
            />
            <div *ngIf="submitted && f['ustatus'].errors" class="invalid-feedback">
              <div *ngIf="f['ustatus'].errors['required']">Status is required</div>
            </div>
        </div> -->
        </div>
       

      
  
      <div class="formbutton">

     
        <button type="submit" class="btn btn-primary" (click)="onSubmit(unitForm.value)">Submit</button>
        <button type="submit" class="btn btn-danger btn2"  (click)="onCancel()">Cancel</button>
      </div>
    </form>
  </div>
  